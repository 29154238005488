const routerBox = {
  name: 'routerBox',
  render() {
    return <router-view />
  },
}
const activeMenu = '/productManage/nvrManage/index'
export default {
  path: 'nvrManage',
  name: 'nvr-manage',
  component: routerBox,
  meta: {
    title: 'NVR监控',
  },
  redirect: '/productManage/nvrManage/index',
  children: [
    {
      path: 'index',
      name: 'nvr-manage-index',
      component: () => import('@/views/nvrManage/index.vue'),
      meta: {
        level: 0,
      },
    },
    {
      path: 'solution',
      name: 'nvr-manage-solution',
      hidden: true,
      meta: {
        title: '新建解决方案',
        activeMenu,
      },
      component: routerBox,
      redirect: '/productManage/nvrManage/solution/index',
      children: [
        {
          path: 'index',
          name: 'nvr-manage-solution-index',
          component: () => import('@/views/nvrManage/solution/index.vue'),
          meta: {
            activeMenu,
          },
        },
      ],
    },
    {
      path: 'device',
      name: 'nvr-manage-device',
      component: routerBox,
      hidden: true,
      redirect: '/productManage/nvrManage/device/index',
      meta: {
        title: '设备管理',
      },
      children: [
        {
          path: 'index',
          name: 'nvr-manage-device-index',
          component: () => import('@/views/nvrManage/device/index.vue'),
          meta: {
            activeMenu,
          },
        }
      ]
    },
    {
      path: 'cameraManage',
      name: 'nvr-manage-cameraManage',
      component: routerBox,
      hidden: true,
      redirect: '/productManage/nvrManage/cameraManage/index',
      meta: {
        title: '摄像头管理'
      },
      children: [
        {
          path: 'index',
          name: 'nvr-manage-cameraManage-index',
          component: () => import('@/views/nvrManage/cameraManage/index.vue'),
          meta: {
            activeMenu,
          }
        },
        {
          path: 'live',
          name: 'nvr-manage-cameraManage-live',
          component: () => import('@/views/nvrManage/cameraManage/NvrLive.vue'),
          meta: {
            title: '直播',
            activeMenu,
          }
        }
      ]
    }
  ]
}
