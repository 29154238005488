<template>
  <el-container>
    <el-header>
      <Header />
    </el-header>
    <el-container>
      <el-aside class="aside-container" width="200px">
        <el-menu class="sidebar-menu" :default-active="activeMenu">
          <sidebar-item
            v-for="route in sideRoutes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
          ></sidebar-item>
        </el-menu>
      </el-aside>
      <el-main class="main-container">
        <breadcrumb v-show="this.$route.meta.level !== 0"></breadcrumb>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from './components/Header.vue'
import SidebarItem from './components/SidebarItem.vue'
import { asyncRoutes } from '@/router'
import Breadcrumb from '@/components/Breadcrumb.vue'
import { cloneDeep } from 'lodash'

export default {
  components: {
    Header,
    SidebarItem,
    Breadcrumb,
  },
  data() {
    return {
      sideRoutes: [],
    }
  },
  computed: {
    activeMenu() {
      const { meta, path } = this.$route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  created() {
    const filterRoutes = (routes) => {
      return routes.reduce((arr, curr) => {
        if (!curr.hidden) {
          if (curr.children) {
            curr.children = filterRoutes(curr.children)
          }
          arr.push(curr)
        }
        return arr
      }, [])
    }
    this.sideRoutes = filterRoutes(cloneDeep(asyncRoutes))
  },
  methods: {
    handleClickMenu(e) {
      this.$router.push(e.key)
    },
    resolvePath(basePath, routePath) {
      return basePath + '/' + routePath
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '~@/styles/variables.less';

.aside-container {
  position: fixed;
  top: @header-height;
  bottom: 0;
  box-shadow: 1px 0 4px rgba(0, 21, 41, 0.08);
}
.sidebar-menu {
  text-align: left;
  border-right: none;
}

.el-header {
  position: fixed;
  left: 0;
  right: 0;
  background: #5c76cc;
  height: @header-height !important;
  padding: 0 20px;
  z-index: 10000;
}
</style>
