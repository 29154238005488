import Layout from '@/layout/index.vue'

const RouterBox = {
  name: 'RouterBox',
  render() {
    return <router-view />
  },
}

const boxListRoute = '/box/query/index'

export default [
  {
    path: '/box',
    name: 'box',
    component: Layout,
    meta: {
      title: '盒子管理',
      icon: 'iconfont icon-1box',
      showChildMenu: true,
    },
    children: [
      {
        path: 'query',
        name: 'box-query',
        component: RouterBox,
        redirect: '/box/query/index',
        meta: {
          title: '运行查询',
        },
        children: [
          {
            path: 'index',
            name: 'box-query-index',
            component: () => import('@/views/boxManage/query/index.vue'),
            meta: {
              level: 0,
              keepAlive: true
            },
          },
          {
            path: 'camera',
            name: 'box-query-camera',
            component: () => import('@/views/box/retailTraffic/index.vue'),
            meta: {
              title: '摄像头管理',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'deviceCamera',
            name: 'box-query-deviceCamera',
            component: () => import('@/views/box/device/index.vue'),
            meta: {
              title: '红外设备-摄像头管理',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'monitor',
            name: 'box-query-monitor',
            component: () => import('@/views/box/security/index.vue'),
            meta: {
              title: '安防监控-摄像头管理',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'change',
            component: () => import('@/views/box/retailTraffic/change.vue'),
            meta: {
              title: '更换',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'deviceChange',
            component: () => import('@/views/box/retailTraffic/change.vue'),
            meta: {
              title: '红外设备-更换',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'monitorChange',
            component: () => import('@/views/box/retailTraffic/change.vue'),
            meta: {
              title: '安防监控-更换',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'live',
            component: () => import('@/views/live/index.vue'),
            meta: {
              title: '直播',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'deviceLive',
            component: () => import('@/views/live/index.vue'),
            meta: {
              title: '红外设备-直播',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'monitorLive',
            component: () => import('@/views/live/index.vue'),
            meta: {
              title: '安防监控-直播',
              activeMenu: boxListRoute,
            },
            hidden: true,
          },
          {
            path: 'solution',
            name: 'box-query-solution',
            component: () => import('@/views/retailTraffic/solution/index.vue'),
            meta: {
              title: '新建解决方案',
              activeMenu: boxListRoute,
            },
            hidden: true,
          }
        ],
      },
    ],
  },
]




